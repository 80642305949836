<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <div class="billDetails">
            <div class="billDetails-top">
                <div class="datepick">
                    <le-input-date label="" v-model="balanceDate"></le-input-date>
                </div>
                <div class="a-flex-rfsc a-flex-wrap billDetails-top-box">
                    <div class="billDetails-top-item a-flex-cfsfs">
                        <span class="a-c-normal a-pb-10">自营订单量</span>
                        <span class="a-c-master a-fs-24 a-fw-700">{{ balanceInfo&&balanceInfo.myStationOrderNum?balanceInfo.myStationOrderNum:0 }}</span>
                    </div>
                    <div class="billDetails-top-item a-flex-cfsfs">
                        <div class="a-flex-rfsc a-pb-10">
                            <span class="a-c-normal">自营订单总金额</span>
                            <el-tooltip class="item" popper-class="c-tooltip" content="包含现金/红包/赠送金" placement="top">
                                <img src="../../assets/icon/instruct-icon.png" class="a-wh-16 a-ml-11" />
                            </el-tooltip>
                        </div>
                        <div class="a-flex-rfsc">
                            <span class="a-c-master a-fs-20 a-fw-700">￥</span>
                            <span class="a-c-master a-fs-24 a-fw-700">{{ balanceInfo&&balanceInfo.myStationOrderAmount?util.numFormat(balanceInfo.myStationOrderAmount):0 }}</span>
                        </div>
                    </div>
                    <div class="billDetails-top-item a-flex-cfsfs">
                        <span class="a-c-normal a-pb-10">自营收益(元)</span>
                        <div class="a-flex-rfsc">
                            <span class="a-c-master a-fs-20 a-fw-700">￥</span>
                            <span class="a-c-master a-fs-24 a-fw-700">{{ balanceInfo&&balanceInfo.myStationIncomeAmount?util.numFormat(balanceInfo.myStationIncomeAmount):0 }}</span>
                        </div>
                    </div>
                    <div class="billDetails-top-item a-flex-cfsfs">
                        <span class="a-c-normal a-pb-10">自营站点数量</span>
                        <span class="a-c-master a-fs-24 a-fw-700">{{ balanceInfo&&balanceInfo.myStationSize?balanceInfo.myStationSize:0 }}</span>
                    </div>
                    <div class="billDetails-top-item a-flex-cfsfs">
                        <span class="a-c-normal a-pb-10">设备使用率</span>
                        <span class="a-c-master a-fs-24 a-fw-700">{{ balanceInfo&&balanceInfo.deviceUsePercent?(balanceInfo.deviceUsePercent*100).toFixed(2):0 }}%</span>
                    </div>
                    <div class="billDetails-top-item a-flex-cfsfs">
                        <span class="a-c-normal a-pb-10">代理订单量</span>
                        <span class="a-c-master a-fs-24 a-fw-700" >{{ balanceInfo&&balanceInfo.otherStationOrderNum?balanceInfo.otherStationOrderNum:0 }}</span>
                    </div>
                    <div class="billDetails-top-item a-flex-cfsfs">
                        <div class="a-flex-rfsc a-pb-10">
                            <span class="a-c-normal">代理订单总金额</span>
                            <el-tooltip class="item" popper-class="c-tooltip" content="包含现金/红包/赠送金" placement="top">
                                <img src="../../assets/icon/instruct-icon.png" class="a-wh-16 a-ml-11" />
                            </el-tooltip>
                        </div>
                        <div class="a-flex-rfsc">
                            <span class="a-c-master a-fs-20 a-fw-700">￥</span>
                            <span class="a-c-master a-fs-24 a-fw-700" >{{ balanceInfo&&balanceInfo.otherStationOrderAmount?util.numFormat(balanceInfo.otherStationOrderAmount):0 }}</span>
                        </div>
                    </div>
                    <div class="billDetails-top-item a-flex-cfsfs">
                        <span class="a-c-normal a-pb-10">代理收益(元)</span>
                        <div class="a-flex-rfsc">
                            <span class="a-c-master a-fs-20 a-fw-700">￥</span>
                            <span class="a-c-master a-fs-24 a-fw-700" >{{ balanceInfo&&balanceInfo.otherStationIncomeAmount?util.numFormat(balanceInfo.otherStationIncomeAmount):0 }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <el-card shadow="never" class="a-mt-20">
                <div slot="header" class="a-fs-16 a-fw-b">收益站点列表</div>
                <le-pagview @setData="setTableData" :pageParam="pageParam">
                    <el-table ref="deviceList" :data="tableData" :highlight-current-row="true" style="width: 100%">
                        <el-table-column type="index" label="序号" width="80"></el-table-column>
                        <el-table-column prop="name" label="站点名称" min-width="200"></el-table-column>
                        <el-table-column prop="deviceNum" label="设备数量" min-width="103"></el-table-column>
                        <el-table-column prop="avgOrderAmount" label="平均金额(元)" min-width="103">
                            <template slot-scope="{ row }">
                                <span>￥{{ util.numFormat(row.avgOrderAmount) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="deviceUsePercent" label="设备使用率" min-width="103">
                            <template slot-scope="{ row }">
                                <span>{{ row.deviceUsePercent?row.deviceUsePercent:0  }}%</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="avgOrderTime" label="平均时长" min-width="103">
                            <template slot-scope="{ row }">
                                <span>{{ row.avgOrderTime?row.avgOrderTime:0  }}分钟</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="orderNum" label="订单数" min-width="103"></el-table-column>
                        <el-table-column prop="stationName" label="总收益(元)" min-width="103">
                            <template slot-scope="{ row }">
                                <span>￥{{ util.numFormat(row.income) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="操作" width="75" fixed="right">
                            <template slot-scope="scope">
                                <el-button type="text" @click="toRevenueBillOrderList(scope.row)">查看订单</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </le-pagview>
            </el-card>
        </div>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                util: util,
                balanceDate: '',// 日期查询
                companyId: '',// 商户id
                balanceInfo: '',// 账单详情
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.getStationListByBill,
                    method: "post",
                    params: {
                        balanceDate: '',
                        companyId: ''
                    },
                    freshCtrl: -1,
                },
            }
        },
        mounted () {
            this.companyId = this.$route.query.companyId
            this.balanceDate = this.$route.query.balanceDate
            this.pageParam.params.companyId = this.$route.query.companyId
        },
        watch: {
            balanceDate (val) {
                this.pageParam.params.balanceDate = val
                this.pageParam.freshCtrl++
                this.getBalanceDateDetail()
            }
        },
        methods:{
            // 获取收益账单详情
            getBalanceDateDetail () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getBalanceDateDetail,
                    method: "get",
                    params: {
                        companyId: this.companyId,
                        balanceDate: this.balanceDate
                    }
                }).then(res => {
                    if(res.result.code == 0){
                        this.balanceInfo = res.result.data
                    }else{
                        this.$message.error(res.result.message)
                        this.balanceInfo = ''
                    }
                }).catch(err=>{
                    this.balanceInfo = ''
                })
            },
            setTableData (datas) {
                this.tableData = datas
            },
            toRevenueBillOrderList (datas) {
                this.$router.push({
                    path: '/revenueBills/revenueBills-orderList',
                    query: {
                        balanceDate: this.balanceDate,
                        stationId: datas.id
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.billDetails{
    font-size: 14px;
    height: 100%;
    overflow-y: auto;
}
.billDetails-top{
    background: #FFFFFF;
    border-radius: 5px;
}
.datepick{
    padding: 10px 8px 0
}
.billDetails-top-box{
    padding: 0 20px 11px
}
.billDetails-top-item{
    width: 300px;
    height: 100px;
    padding: 21px 11px;
    margin-bottom: 21px
}
</style>